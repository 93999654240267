<template>
<v-row>
    <v-col lg="12">
        <s-crud height='400' title="Parámetros" pdf excel edit add remove restore sortable search-input :config="this.config" @save="savePrm($event)">
            <template slot-scope="props">
                <v-container v-if="props.item != null">
                    <v-row>
                        <v-col cols="12" md="6" class="s-col-form">
                            <s-text v-model="props.item.PrmName" label="Parámetro" autofocus />
                        </v-col>
                        <v-col cols="12" md="6" class="s-col-form">
                            <s-text v-model="props.item.PrmDescription" label="Descripción" />
                        </v-col>
                        <v-col cols="4" class="s-col-form">
                            <s-select-definition :def="1028" label="Tipo Valor" v-model="props.item.TypeValue" />
                        </v-col>
                        <v-col cols="8" class="s-col-form">
                            <s-text v-model="props.item.PrmValue" label="Valor" />
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            <template v-slot:SecStatus="{ row }">
          <v-icon :color="row.SecStatus == 1 ? 'success' : 'default'"> mdi-checkbox-blank-circle</v-icon>
        </template>
        </s-crud>
    </v-col>
</v-row>
</template>

<script>
import _sParameter from "@/services/General/ParameterService";
export default {
    data: () => ({
        config: {
            model: {
                PrmID: "ID",
                PrmName: "string",
                PrmDescription: "string",
                TypeValue: "boleam",
                TypeValueText: "string",
                SecStatus: "status",
                TypeCampaign: "boleam",
                TypeCampaignText: "string",
            },
            service: _sParameter,
            headers: [{
                    text: "ID",
                    align: "end",
                    sortable: true,
                    value: "PrmID",
                    width: "10%",
                },
                {
                    text: "Parámetro",
                    value: "PrmName",
                    width: "20%",
                    sortable: true,
                },
                {
                    text: "Descripción",
                    value: "PrmDescription",
                    width: "45%",
                    sortable: true,
                },
                {
                    text: "Tipo",
                    value: "TypeValueText",
                    width: "10%",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Valor",
                    value: "PrmValue",
                    width: "25%",
                    sortable: false,
                },

                {
                    text: "Estado",
                    value: "SecStatus",
                    width: "5%",
                    align: "center",
                    sortable: false,
                },
            ],
        },
    }),

    methods: {
        validatedPrm(item) {
            let isValid = false;
            let message = "";

            isValid = item.PrmName.length > 0;
            if (!isValid) message = "Ingrese nombre de parámetro";
            else {
                isValid = item.PrmDescription.length > 0;
                if (!isValid) message = "Ingrese descripción";
                else {
                    isValid = item.TypeValue > 0;
                    if (!isValid) message = "Seleccione tipo de valor";
                    else {
                        isValid = item.PrmValue.length > 0;
                        if (!isValid) message = "Ingrese valor de parámetro.";
                    }
                }
            }

            if (!isValid) this.$fun.alert(message, "warning");

            return isValid;
        },
        savePrm(item) {
            if (this.validatedPrm(item)) item.save();
        },
    },
};
</script>
